import React from 'react';
import {NavLink} from 'react-router-dom';
import absotule from '../images/absotule.png';
import feature from '../images/feature.png';
import security from '../images/security.png';
import ngright from '../images/ng-right.png';
import sme from '../images/SME.png';
import enter from '../images/enter.png';
import solution from '../images/solution.png';
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import './timeline.scss';
import Testimonials from '../testimonial';
import TestimonialsClient from '../testimonialClient';
import FluidAnimation from 'react-fluid-animation'

function OurApproach() {
    const processSteps = [
        {number: "01", text: "A Mesh of Mines spread across your Infrastructure"},
        {number: "02", text: "Threat actors are lured to step in on the deceptive mines"},
        {number: "03", text: "Alerts generated and captured by Command & Control Server"},
        {number: "04", text: "Administrators alerted of the triggered mines, the very same minute"}
    ];

    return <div className="solution">
        <div className="container">
            <div className="headingDataInfo">
                <h2>Our Approach</h2>
                <p></p>
            </div>
            <div className="outerProcess">
                <div className="timeline">
                    <div className="outer">
                        {processSteps.map((step, index) => (
                            <div className="cardNew" key={index}>
                                <div className="info">
                                    <h3 className="title">{step.number}</h3>
                                    <p>{step.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function Zero() {
    return <div className="solution">
        <div className="headingDataInfo">
            <span className="childHead"></span>
            <h2>How We Distinguish From The <b>Industry?</b></h2>
        </div>
        <div className="container">
            <div className="technologySection justify-content_space-around">
                <div className="circleZeroData">
                    <div className="infodataCnt">
                        <div className="cardNewSectionInfo">
                            <h3> Zero Dependencies</h3>
                            <p>There are absolutely zero dependencies needed to install Mines in your
                                network.</p>
                        </div>
                        <div className="cardNewSectionInfo">
                            <h3>Zero Coding Skills</h3>
                            <p>There are no coding skills required to deploy any Mine. We have made
                                it a piece of cake to grab by and throw it at your desirable place.</p>
                        </div>
                    </div>
                    <div className="CenterCircleInfo">Zero</div>
                    <div className="infodataCnt">
                        <div className="cardNewSectionInfo">
                            <h3>Zero Monitoring</h3>
                            <p>The Innocent Mines reside without ever collecting any data. Their sole purpose is
                                to alert as soon as someone steps on them.</p>
                        </div>
                        <div className="cardNewSectionInfo">
                            <h3>Zero Bandwidth</h3>
                            <p>Even if thousands of mines are placed, No bandwidth of RAM or Network is
                                utilised.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function AllinOne() {
    return <div className="solution">
        <div className="container">
            <div className="headingDataInfo">
                <h2>All-in One Solution</h2>
            </div>
            <ul className="process">
                <li className="process__item">
                    <span className="process__number">01</span>
                    <span className="process__title">Ensure rogue internal users are caught in time</span>
                </li>

                <li className="process__item">
                    <span className="process__number">02</span>
                    <span className="process__title">Prevent Social Engineering Attacks</span>
                </li>

                <li className="process__item">
                    <span className="process__number">03</span>
                    <span className="process__title">Help in implementing Zero Trust Architecture</span>
                </li>

                <li className="process__item">
                    <span className="process__number">04</span>
                    <span className="process__title">Get attacker Identifiable information</span>
                </li>
            </ul>
            <div className="headingDataInfo">
                <p>We also provide managed mine deployment and Incident Management services</p>
            </div>
        </div>
    </div>;
}

function OurServices() {
    return <div className="ourServices">
        <div className="container">
            <h5>(P)Ease of Mind</h5>
            {/*<p>We Provide best Digital Mines Services </p>*/}
            <br/><br/>
            <div className="row">
                <div className="col-md-4">
                    <div className="feature-box h-100 text-center px-4 py-5">
                        <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg"
                                                               alt=""/></div>
                        <h3 className="feature-box-title">Click and Forget?</h3>
                        <p className="feature-box-desc">Single click deployment of mines in all your endpoints with our
                            in-built deployment algorithm customised for varied operating systems and endpoints.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div
                        className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                        <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/>
                        </div>
                        <h3 className="feature-box-title">Dedicated Dashboard</h3>
                        <p className="feature-box-desc">A single dashboard to manage mines for all the endpoints in your
                            company. Get alerts, notifications, graphs and search capabilities in a one place.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="feature-box h-100 text-center px-4 py-5">
                        <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg"
                                                               alt=""/></div>
                        <h3 className="feature-box-title">Unlimited Mines</h3>
                        <p className="feature-box-desc">With an ever increasing count of Mines, we are targeting
                            hundreds of services, OS, Hardware's, cloud, IoT devices and more. With an active
                            subscription, get access to all new mines for FREE!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function OurClients() {
    return <div className="company-url clientdata">
        <div className="headingDataInfo"><h2>Our <b>Distinguished Clients </b></h2></div>
        <div className="container">
            <div className="clientOuter">
                <span className="imglogo">
                    <img style={{width: 55}} src="./Logo/headlock.jpeg" alt="client"/>
                </span>
                <span className="imglogo">
                    <img style={{width: 200}} src="./Logo/amit.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img style={{width: 130}} src="./Logo/sai.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img style={{width: 111}} src="./Logo/nexus.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="https://globalpda.com/wp-content/uploads/2020/05/logo.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/mine2.png" alt="client"/>
                </span>
                {/* <span className="imglogo rocket">
                    <img src="https://rocketfuel.inc/wp-content/uploads/2022/10/RocketFuelLogoR.svg" alt="client"/>
                </span> */}
                <span className="imglogo">
                    <img src="./Logo/senger.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/baba_saheb.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/yoginis.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/gcs.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/globalkidsolympiads_logo.png" alt="client"/>
                </span>
                <span className="imglogo">
                    <img src="./Logo/kaushik.png" alt="client"/>
                </span>
            </div>
        </div>
    </div>;
}

function OurCustomers() {
    return <div className="testimonialsInfo">
        <div className="container">
            <div className="row">
                <div className="col-sm-7">
                    <div className="contentTst">
                        <h5>Our Customers</h5>
                        <p>Read our success stories to see how we've helped businesses achieve top search engine
                            rankings and significant ROI through expert digital marketing strategies.</p>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="sliderTest">
                        <Testimonials/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function SpeakWithUs() {
    return <div className="footerSubs">
        <div className="container">
            <div className="flexInfo">
                <h3>Want To Speak With Our Solution Experts?</h3>
                <NavLink to="/contact">
                    <button className="bookingInfo">Book a Meeting</button>
                </NavLink>
            </div>
        </div>
    </div>;
}

function DataBreaches() {
    return <div className="outerTrustedPartner box-shadow">
        <div className="container">
            <h2>Why Digital Mines?</h2>
            <p></p>
            <TestimonialsClient/>
            <p>You would not know you have been breached until it's too late. We tell you on the first day with 0 false
                positives and 100% accuracy of breach point. The best part is you deploy these digital mines with a single
                click and they blend in your environment beautifully, sitting innocent and dormant until triggered by
                threat actor!</p>
        </div>
    </div>
}

function BookMeeting() {
    return <div className="container">
        <div className="outerTrustedPartner borderTop">
            <div className="contentInfoDataStay">
                <div className="contentsty">
                    <h1>The World's First Digital Landmines</h1>
                    <p>Stay ahead of attackers trying to enter the premises and exfiltrate data </p>
                </div>
                <NavLink to="/contact">
                    <button className="bookingInfo">Book a Meeting</button>
                </NavLink>
            </div>
        </div>
    </div>;
}

function Main() {
    return (
        <div>
            <BookMeeting/>
            <DataBreaches/>
            <OurApproach/>
            <Zero/>
            <AllinOne/>
            <OurServices/>
            {/*<div className="container marketing">*/}
            {/*</div>*/}
            {/*<OurClients/>*/}
            {/*<OurCustomers/>*/}
            <SpeakWithUs/>
        </div>
    )
        ;
}

export default Main;