import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './faq.scss';
import Header from "../header";
import Footer from "../footer";

const faqData = [
    {
        question: "What are mines?",
        answer: "Mines are small pieces of code which are meant to lure attackers. These are curated and carefully placed in locations which remain dormant until executed by an adversary. These are hidden in plain sight and are impossible to distinguish until you step and trigger them."
    },
    {
        question: "Where can we place them?",
        answer: "Mines can be placed in servers, desktops, repositories, codebases, files & folders, mobile phones, IoT appliances, Cloud platforms and multiple other digital locations."
    },
    {
        question: "What is the bandwidth usage?",
        answer: "Mines are lightweight, most of the mines will not even take 1 mb of space. Mines do not trigger until executed manually so do not consume network and RAM."
    },
    {
        question: "How to deploy mines?",
        answer: "Mines can be deployed via GUI console for specific endpoints as per your requirement. Mines can also be deployed using automated deployment script which only needs to be run at the targeted endpoint to automatically deploy the necessary mines. The script is intelligent to deploy specific mines at specific locations."
    },
    {
        question: "Can I deploy this in my employee’s PC?",
        answer: "Yes, the mines can be deployed in desktops without a hassle."
    },
    {
        question: "Do I need to have AD for Mines?",
        answer: "No, Mines do not need to have AD or any other dependency for their deployment or trigger."
    },
    {
        question: "What are prerequisites for deploying mines?",
        answer: "There are no prerequisites for Deploying mines. No compilers, dependencies, environment or software/hardware dependencies are there."
    },
    {
        question: "What if Me or my colleague accidentally triggers a mine?",
        answer: "Location of mines need to be kept discreet by the administrator to catch internal threats as well. If any employee accidentally steps on a mine, the incident can be deleted. The mine does not need to be deleted in such cases. However, if multiple mines have been triggered by an employee or a mine has been triggered in a location where the employee is usually not authorized, this can mean internal threat from the employee and should be dealt with accordingly."
    },
    {
        question: "Can I deploy multiple mines on a single endpoint?",
        answer: "Yes, it is actually recommended to deploy multiple mines on a single endpoint. Increased mines have increased chances of catching an intruder."
    },
    {
        question: "Can I deploy the same mine to multiple endpoints?",
        answer: "No, mines are tied to the endpoint. The trigger of mine helps you know the endpoint which has been compromised. If a single mine is present in multiple endpoints, the trigger of that mine would mean an attacker can be at any of those endpoints which doesn’t really help much."
    },
    {
        question: "How are mines priced?",
        answer: "Mines are priced in batches. Batches of 50 to 500 mines can be purchased and deployed. For enterprise requirements, please contact for best price."
    },
    {
        question: "Can I deploy mines in VM?",
        answer: "Yes, Mines can be deployed in VMs. If the VMs or any endpoint is deleted, the lost mines can also be deleted from console to get back the mine quota."
    },
    {
        question: "Is there a limit on endpoints in pricing?",
        answer: "No, there is no limit on the endpoints. Any number of endpoints can be configured. However, it is strongly recommended to NOT deploy a single mine on multiple endpoints."
    },
    {
        question: "Is there a user limit?",
        answer: "No, there is no user limit and console access can be given to any number of users in your organization."
    },
    {
        question: "Can Mines function for isolated machines?",
        answer: "For certain mines, it would be better to whitelist Mine2’s IP from the firewall for easy detections of triggers. This will ensure high success rate in catching the intruders."
    },
    {
        question: "Can I get help in Mine Deployments?",
        answer: "Yes, we provide end to end managed service of mine deployment, management, triaging the triggers and incident response."
    },
    {
        question: "What data does Mine2 take from me?",
        answer: "Mine2 only takes your endpoint names to tell you when your mines have been triggered. You are free to use a different nomenclature for this purpose as per your understanding. So overall, none of your company data is taken by Mine2."
    },
    {
        question: "Is console access provided online?",
        answer: "Yes, console.mine2 runs as multi-tenancy web application and provides SSO capabilities."
    },
    {
        question: "What happens when mines are inactive?",
        answer: "Inactive mines are not tracked for triggers but consume the mine quota. These are only to be used when ignoring mine alerts for a certain time period. If a mine is redundant, it is advised to delete and free up the mine quota."
    },
    {
        question: "Are there any guidelines for mine deployment?",
        answer: "Yes, for every new mine, there will be a set of generic guidelines on where the mine should be placed. This can be found when creating a new mine from the console. Mines deployed using auto deployment script are deployed automatically."
    }
];

const FAQ = () => {
    return (
        <div>
            <Header/>
            <div className="container" id={"faqAccordion"}>
                <div className="banner-info">
                    <div className="content">
                        <h1 className="text-center">FAQs</h1>
                        <div className="accordion" style={{pointerEvents: "auto"}}>
                            {faqData.map((faq, index) => (
                                <div className="accordion-item custom-accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className="accordion-button custom-accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse${index}`}
                                        >
                                            {faq.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body custom-accordion-body">
                                            {faq.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

        </div>
    );
};

export default FAQ;