import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.css';
import './jobDescription.scss';
import Header from '../header';
import Footer from '../footer';
import jobData from '../dataJob';
import jobDescriptions from '../jobDescriptions';
import { NavLink } from 'react-router-dom';

const Description = () => {
    const { id } = useParams();
    const job = jobData.find(job => job.id === parseInt(id));
    const [modal, setModal] = useState(false);
    const form = useRef();

    if (!job) {
        return <div>Job not found</div>;
    }

    const jobDescriptionContent = jobDescriptions[job.descriptionId];

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);
        const data = {
            name: formData.get('user_name'),
            email: formData.get('user_email'),
            phone_number: formData.get('user_mobile'),
            message: formData.get('message'),
            // my_file: formData.get('my_file')
        };

        fetch('https://console.mine2.io/api/v1/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setModal(true);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="banner-info bgdataUpdate">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>{job.technology}</h1>
                                <p>Location: {job.location}</p>
                                <p>Type: {job.type}</p>
                            </div>
                            <div className="col-sm-4">
                                <img src='/images/description1.png' alt="Job Description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="outerDataInfoJob">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            {/*<div className="col-sm-8">*/}
                                <div dangerouslySetInnerHTML={{ __html: jobDescriptionContent }} />
                            {/*</div>*/}
                            {/*<div className="col-sm-4">*/}
                            {/*    <div className="get-in-touchJob">*/}
                            {/*        <h5>Get in Touch</h5>*/}
                            {/*        <p>Feel free to drop us a line below!</p>*/}
                            {/*        <form ref={form} onSubmit={sendEmail}>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label htmlFor="Name">Name </label>*/}
                            {/*                <input required type="text" className="form-control" name="name" />*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label htmlFor="phone-id">Phone number </label>*/}
                            {/*                <input required type="phone" className="form-control" name="phone_number" />*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label htmlFor="email-id">Email </label>*/}
                            {/*                <input required type="email" className="form-control" name="email" />*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label htmlFor="my-file">Attach CV: </label>*/}
                            {/*                <input required className="form-control" type="file" name="my_file" />*/}
                            {/*            </div>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label htmlFor="message">Message </label>*/}
                            {/*                <textarea required className="form-control" name="message"></textarea>*/}
                            {/*            </div>*/}
                            {/*            <div className="text-center mt-10">*/}
                            {/*                <input className="btn btn-primary waves-effect waves-light" type="submit" value="Submit application" />*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*        {modal &&*/}
                            {/*            <div className='thanksYou'>*/}
                            {/*                <div className="wrapper-2">*/}
                            {/*                    <h1>Thank you!</h1>*/}
                            {/*                    <p>Your submission has been received successfully. Our team is eager to assist you and will be in touch asap.</p>*/}
                            {/*                    <NavLink className="nav-link" to="/">*/}
                            {/*                        <button className="go-home">*/}
                            {/*                            go home*/}
                            {/*                        </button>*/}
                            {/*                    </NavLink>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Description;