import React from 'react';
import './testimonial.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Testimonials() {
  return (
    <div className='owlDataNew'>
      <OwlCarousel className='owl-theme' items="1" loop margin={10}  dots nav autoplay>
      <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="./images/arun.jfif"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Arun Kumar Singh</h6>
                          <p>Engineering Leader @ British Telecom</p>
                          <a href="https://www.linkedin.com/in/arsingh1/" target='_blank'><img src="./images/linkedin.png"/></a>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>Mine2 is a unique concept and one of a kind offering I have ever seen in the cyber security domain. It can open a new segment altogether..!!</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="./images/prasant.jfif"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Prashant Mohan</h6>
                          <p>Cyber Security Architect @ Fidelity</p>
                          <a href="https://www.linkedin.com/in/prashant-mohan-cissp-issap-ccsp-04610215/" target='_blank'><img src="./images/linkedin.png"/></a>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>It’s a promising new tech which was long required to beat the threat of intrusions. I have seen a lot of tools in market for combating different sides of cyber security, this tool marks as a game changer for all of them.</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                           <img src="./images/mohit.jfif"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Mohit Kumar Sharma</h6>
                          <p>Head of Cyber Security @ Lenskart</p>
                          <a href="https://www.linkedin.com/in/mkroot/" target='_blank'><img src="./images/linkedin.png"/></a>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>The only defensive tool purely built on a hacker’s mindset. These guys know hackers more than most organizations have understood till date.</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                           <img src="./images/aman.jfif"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Aman Sachdeva</h6>
                          <p>Senior product engineer @ Atlassian </p>
                          <a href="https://www.linkedin.com/in/amansachdev/" target='_blank'><img src="./images/linkedin.png"/></a>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>After ASM and ZTNA, Mines will be the new addition everyone will line up for. Vouching these guys for technology advancements and their perfection in this tool development.</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
    </OwlCarousel>
   </div>
    ); 
 }

export default Testimonials;