import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './career.scss';
import Header from '../header';
import Footer from '../footer';
import career from '../images/career.png';
import leftCareer from '../images/leftCareer.png';
import rightCareer from '../images/rightCareer.png';
import JobFilter from '../jobFilter';
import { NavLink } from 'react-router-dom';
import { Alert } from 'bootstrap';
import Testimonials from '../testimonial';
import TestimonialsEmployee from '../testimonialEmployee';

const Career = () => {
    const [modal, setModal] = useState(false);

  const sendEmail = (e) => {
    setModal(true)
  };
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Careers</h1>
                          <p>Work at the most dynamic company & unlock your true potential.</p>
                      </div>
                      <div className="col-sm-4">
                         <img src={career}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container marketing">
          <div class="row featurette">
          <div class="col-md-5">
               <img src="../images/bg.png" alt="about"/>
              </div>
              <div class="col-md-7">
              <h6>Career at MINE2</h6>
              <h1>Your Life at MINE2</h1>
              <p>At MINE2 we believe in working together and working hard. With so many happy clients, we are looking for dynamic and creative individuals who are willing to dedicate themselves to providig innovative products and services for our clients.</p>
              </div>
              </div>
          </div>
           <div>
          <div className="container marketing">
          <div class="row featurette" margin="0">
              <div class="col-md-7">
              <h6>We're Hiring</h6>
              <h1>Become one of us?</h1>
              <p>For all  positions, keep in mind that your job will impact so many clients. If you have the skills and think you will thrive on this challenge, we really look forward to meeting you!</p>
              <h3>Send Your Resume</h3>
              <h5>hr@mine2.com</h5>
              </div>
              <div class="col-md-5">
                  <img src={rightCareer}/>
              </div>
              </div>
          </div>
          </div>
           <section className='outerBgDataHr'>
          <div className="container">
          <div class="row featurette">
            <JobFilter/>
          </div>
          </div>
          </section>
          {modal &&
            <div className='thanksYou'>
                <div class="wrapper-2">
                <h1>Sorry! &#128542;</h1>
                <p>Registerations are closed</p>
                <NavLink className="nav-link" to="/"> <button class="go-home">
                go home
                </button>
                </NavLink>
                </div>
            </div>
            }
          <Footer/>
      </div>
    );
}

export default Career;