import React from 'react';
import {NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './terms.scss';
import Header from '../header';
import Footer from '../footer';
import privacy from '../images/privacy.png';

const Terms = () => {
    return (
        <div>
            <Header/>
            <div className="banner-info">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>Terms & Conditions
                                </h1>
                                <p> This is as Agreement Between you or the entity that you represent (hereinafter “You”
                                    or “Your”) and MINE2 </p>
                            </div>
                            <div className="col-sm-4">
                                <img src={privacy} alt="Privacy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-sm-12">
                        <div className="info-hsn">
                            <h2>Terms and Conditions of Use</h2>
                            <p>The following terms and conditions of use apply to the use of this Website (the
                                “Website”). Read these terms and conditions carefully before using the Website with or
                                without logging in or downloading content from the site. By accessing or using the
                                Website, you are accepting and agreeing to the terms below. If a part of this user
                                agreement is or ever becomes illegal, the rest of the agreement will remain
                                enforced.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>License</h2>
                            <p>Subject to the terms and conditions herein, Mine2 hereby grants you a limited,
                                nontransferable and nonexclusive license, subject to the restrictions set forth below,
                                to access and use the Website, solely for informational and non-commercial purposes, for
                                internal use and/or for the purpose of using, managing and supporting Mine2 services and
                                applications owned or controlled by you (the “License”). Mine2 reserves the right to
                                amend the terms of this License from time to time without notice, by posting the revised
                                terms on the Website.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Copying and Reverse Engineering</h2>
                            <p>You agree that you will not: (i) copy, modify, create any derivative work of; or (ii)
                                reverse assemble, decompile, reverse engineer or otherwise attempt to derive source
                                code; or (iii) remove any copyright notices, identification or any other proprietary
                                notices from any of the software, copyrighted content and any proprietary information in
                                this Website.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Intellectual Property Rights</h2>
                            <p>You acknowledge and agree that this License is not intended to convey or transfer to you
                                any intellectual property rights or to grant any licenses in or to any technology or
                                intellectual property or content, other than as expressly provided herein. The content
                                contained in this Website, including, but are not limited to, software, product
                                information, technology information, user guides, white papers, analysis, trade names,
                                graphics, designs, icons, audio or video clips and logos, is Mine2 proprietary
                                information, protected by copyright, trademark, patent and/or other intellectual
                                property rights, under US and international law. Third-party trademarks and information
                                are the property of their respective owners.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Disclaimer of Warranty</h2>
                            <p>Although Mine2 attempts to provide accurate and up-to-date information on this Website,
                                Mine2 makes no warranty with respect to the accuracy or completeness of the information
                                on the Website. Information, software and documentation provided on this Website are
                                provided “as is” and without warranty of any kind either expressed or implied,
                                including, but not limited to, the implied warranties of merchantability and fitness for
                                a particular purpose and non-infringement.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Limitation of Liability</h2>
                            <p>Mine2 shall not be liable to you or any other party for any indirect, special, incidental
                                or consequential damages, including, but not limited to, any amounts representing loss
                                of profits, loss of business, loss of information or punitive damages. In any event, the
                                extent of liability shall not exceed the amount of US$20. The above limitations shall
                                apply to the fullest extent permitted by law.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Links to Third-party Websites</h2>
                            <p>This Website may contain links to third-party Websites. Such links are provided for
                                convenience only and Mine2 makes no warranty, nor does it assume any responsibility or
                                liability in connection with the access and use of any other Website.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Privacy</h2>
                            <p>Information submitted by you or collected by us in connection with the use of this
                                Website is subject to our Privacy Policy, the terms of which are incorporated herein by
                                reference.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Export</h2>
                            <p>The information, products or services available on this Website or any part thereof may
                                be subject to export or import controls under the laws and regulations of the United
                                States and/or Israel. You agree to comply with such laws and regulations and agree not
                                to knowingly export, re-export, import or re-import, or transfer products without first
                                obtaining all required government authorizations or licenses.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Governing Law</h2>
                            <p>This Agreement and any action related thereto shall be governed, controlled, interpreted
                                and defined in accordance with the laws of the State of Israel, without regard to the
                                conflicts of laws provisions thereof.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>Safe Harbor</h2>
                            <p>This Website may contain forward-looking statements that are subject to risks and
                                uncertainties. Factors that could cause actual results to differ materially from these
                                forward-looking statements include, but are not limited to, general business conditions
                                COPYRIGHT © 2024, Mine2 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Terms;