import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import './header.scss';
import { Helmet } from "react-helmet";
const Header = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "MINE2 Technologies Pvt Ltd",
        "alternateName": "MINE2",
        "url": "https://www.mine2.io",
        "logo": "https://www.mine2.io/images/logo.png",
        "sameAs": [
            "https://www.facebook.com/people/MINE2/100069623461291/",
            "https://www.instagram.com/MINE2_inc/",
            "https://www.linkedin.com/company/mine2-technologies/",
            ""
        ]
    }

    const gtagScript = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-RZL6LMYG8F');
    `;
  return (
    <React.Fragment>
        <Helmet>
            <title>MINE2: Digital Mines</title>
            <meta name="description" content="Digital Mines, Detecting Intrusions in 0 Days"/>
            <link rel="canonical" href="https://www.mine2.io"/>
            <meta name="keywords" content="Digital Mines, Detecting Intrusions in 0 Days"/>

            <meta property="og:title" content="MINE2: Digital Mines"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.mine2.io/"></meta>
            <meta property="og:description" content="Digital Mines, Detecting Intrusions in 0 Days"/>

            <meta property="og:image" content="/images/logo.png"/>
            <meta property="og:image:secure_url" content="/images/logo.png"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="628"/>
            <meta property="og:image:type" content="image/png"/>

            <meta name="twitter:title" content="MINE2: Digital Mines"/>
            <meta name="twitter:url" content="https://mine2.io/"/>
            <meta name="twitter:domain" value="mine2.io"/>
            <meta name="twitter:card" content="Digital Mines, Detecting Intrusions in 0 Days"></meta>
            <meta name="twitter:description" content="Digital Mines, Detecting Intrusions in 0 Days"/>


            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
            {/*<script async src="https://www.googletagmanager.com/gtag/js?id=G-RZL6LMYG8F"></script>*/}

            {/*    <script dangerouslySetInnerHTML={{__html: gtagScript}}/>*/}

        </Helmet>
        <StickyHeader
            header={

                <header className="header-top">
                <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container">
                            <NavLink  className="navbar-brand" to="/"><img src="/images/logo.png" className="App-logo" alt="logo" /></NavLink>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto action-buttons">
                <li class="nav-item">
                <NavLink exact activeClassName="active" className="nav-link" to="/">Home <span class="sr-only">(current)</span></NavLink>
                </li>
                {/*<li class="nav-item dropdown">*/}
                    {/*<NavLink activeClassName="active" className="nav-link" to="/services">Services</NavLink>*/}
                    {/* <ul class="dropdown-menu">
                        <li><NavLink className="dropdown-item" to="/services">Our Services</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/seo-digital-marketing/">SEO & Digital Marketing</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/website-&-applications">Website & Applications</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/our-skillsets">Our Skillsets</NavLink></li>
                    </ul> */}
                {/*</li>*/}
                <li class="nav-item">
                    <div><a className='nav-link' href="https://console.mine2.io/sign-in" target='_blank'>Product</a></div>
                </li>
                <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/about">About Us</NavLink>
                </li>
                {/*<li class="nav-item dropdown">*/}
                {/*    <a className='nav-link' href='javascript:void(0)'> Career <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></a>*/}
                {/*    <ul class="dropdown-menu">*/}
                {/*        <li><NavLink className="dropdown-item" to="/career">Current Positions</NavLink></li>*/}
                {/*        <li><NavLink className="dropdown-item" to="/internship-program">Internship Program</NavLink></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                    <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/contact">Contact Us</NavLink>
                </li>
                </ul>
            </div>
        </div>
        </nav>
    </header>
    } >
     </StickyHeader>
     </React.Fragment>
  );
}

export default Header;
