// jobDescriptions.js
const jobDescriptions = {
  qa: `
     <h6>About the Role:</h6>
     <p><strong>Position: Quality Assurance Engineer</strong></p>

<p><strong>Job Description:</strong></p>

<p>We are seeking a meticulous and technically proficient Quality Assurance (QA) Engineer to join our dynamic team. As a QA Engineer, you will play a crucial role in ensuring the quality and reliability of our SaaS platform. You will be responsible for testing the console that manages user endpoints, configurations, and alerts, as well as verifying that various triggers are received correctly across different service types.</p>

<p><strong>Responsibilities:</strong></p>
<ul>
  <li>Develop and execute test plans, test cases, and test scripts to ensure the quality and functionality of our SaaS platform.</li>
  <li>Perform thorough testing of the console to manage user endpoints and service configurations.</li>
  <li>Verify that alerts and triggers are generated correctly for different service types.</li>
  <li>Collaborate with the development team to identify, document, and resolve bugs and performance issues.</li>
  <li>Conduct functional, regression, and performance testing to ensure system stability and reliability.</li>
  <li>Provide detailed reports on testing activities, including test results, coverage, and identified issues.</li>
  <li>Work closely with product managers and developers to understand system requirements and design appropriate test strategies.</li>
</ul>

<p><strong>Requirements:</strong></p>
<ul>
  <li>Bachelor’s degree in Computer Science, Information Technology, or a related field.</li>
  <li>Proven experience as a QA Engineer or similar role in a SaaS or cloud-based environment.</li>
  <li>Strong understanding of QA methodologies, tools, and processes.</li>
  <li>Familiarity with basic cloud concepts, HTTP protocols, and database systems.</li>
  <li>Experience with automated testing tools and frameworks.</li>
  <li>Excellent problem-solving skills and attention to detail.</li>
  <li>Strong communication skills and the ability to work collaboratively in a team environment.</li>
</ul>

<p><strong>Preferred Qualifications:</strong></p>
<ul>
  <li>Experience in security testing and monitoring alert systems.</li>
  <li>Knowledge of continuous integration and continuous deployment (CI/CD) pipelines.</li>
  <li>Certifications in QA methodologies or cloud technologies.</li>
</ul>

<p>If you are passionate about quality assurance and eager to contribute to a growing SaaS platform, we would love to hear from you. Join us in delivering secure and reliable services to our customers.</p>

  `,
  marketingIntern: `
    <h6>About the Role:</h6>
    <p><strong>Position: Marketing Intern</strong></p>

<p><strong>Job Description:</strong></p>

<p>We are seeking a motivated Marketing Intern to join our team and support the promotion of our B2B cybersecurity product, Digital Mine IDS. This role offers a unique opportunity to gain experience in the B2B marketing space, focusing on targeted outreach, content creation, and strategic campaigns aimed at business clients.</p>

<p><strong>Responsibilities:</strong></p>
<ul>
  <li>Assist in developing and executing targeted marketing campaigns to promote Digital Mine IDS to business clients.</li>
  <li>Support cold outreach efforts, including drafting and personalizing emails, LinkedIn messages, and other communications to potential clients.</li>
  <li>Create engaging content such as whitepapers, case studies, blog posts, and newsletters tailored to industry-specific audiences.</li>
  <li>Help organize and promote webinars, workshops, and other educational events to engage potential clients and demonstrate thought leadership.</li>
  <li>Conduct market research to identify potential clients, industry trends, and competitive insights.</li>
  <li>Monitor and analyze marketing metrics to assess the effectiveness of campaigns and suggest improvements.</li>
  <li>Collaborate with the sales team to develop materials that support their outreach and client engagement efforts.</li>
</ul>

<p><strong>Requirements:</strong></p>
<ul>
  <li>Currently pursuing a degree in Marketing, Communications, Business, or a related field.</li>
  <li>Interest in B2B marketing and cybersecurity.</li>
  <li>Strong written and verbal communication skills, with the ability to create professional and compelling content.</li>
  <li>Familiarity with digital marketing tools and platforms, including LinkedIn and email marketing software.</li>
  <li>Basic understanding of marketing principles and strategies, especially in a B2B context.</li>
  <li>Detail-oriented with strong organizational skills and the ability to manage multiple tasks.</li>
  <li>Ability to work independently and collaboratively in a team environment.</li>
</ul>

<p><strong>Preferred Qualifications:</strong></p>
<ul>
  <li>Experience with cold outreach or account-based marketing (ABM).</li>
  <li>Knowledge of SEO and content marketing strategies.</li>
  <li>Experience with graphic design tools such as Adobe Creative Suite or Canva.</li>
  <li>Previous internship experience in B2B marketing or related fields.</li>
</ul>

<p>If you are eager to learn and contribute to the growth of a cutting-edge B2B cybersecurity product, we encourage you to apply. Join us and gain valuable experience in a dynamic, fast-paced environment.</p>

  `,
  frontendDeveloper: `
    <h6>About the Role:</h6>
    <p><strong>Position: Front-End Developer</strong></p>

<p><strong>Job Description:</strong></p>

<p>We are looking for a talented and passionate Front-End Developer to join our team. In this role, you will be responsible for developing and maintaining our main website (Portal) and customer-facing application (Console). Both projects leverage React as the primary framework, with the Console also utilizing TypeScript for enhanced development capabilities.</p>

<p><strong>Responsibilities:</strong></p>
<ul>
  <li>Develop and maintain the Portal and Console using React and TypeScript.</li>
  <li>Collaborate with designers and backend developers to create seamless and visually appealing user interfaces.</li>
  <li>Implement responsive and accessible design principles to ensure an optimal user experience across devices.</li>
  <li>Write clean, maintainable, and efficient code, following best practices and standards.</li>
  <li>Optimize applications for maximum performance and scalability.</li>
  <li>Identify and troubleshoot UI/UX issues and ensure timely resolution.</li>
  <li>Contribute to the continuous improvement of our development processes and tools.</li>
</ul>

<p><strong>Requirements:</strong></p>
<ul>
  <li>Bachelor’s degree in Computer Science, Web Development, or a related field.</li>
  <li>Proven experience as a Front-End Developer, with a strong portfolio of web applications.</li>
  <li>Proficiency in React and TypeScript, with a solid understanding of their ecosystems.</li>
  <li>Strong knowledge of HTML, CSS, JavaScript, and modern front-end technologies.</li>
  <li>Experience with state management libraries (e.g., Redux, MobX).</li>
  <li>Familiarity with RESTful APIs and asynchronous request handling.</li>
  <li>Understanding of version control systems, preferably Git.</li>
  <li>Excellent problem-solving skills and attention to detail.</li>
  <li>Strong communication skills and the ability to work collaboratively in a team environment.</li>
</ul>

<p><strong>Preferred Qualifications:</strong></p>
<ul>
  <li>Experience with design tools such as Figma or Adobe XD.</li>
  <li>Knowledge of CI/CD pipelines and deployment processes.</li>
  <li>Experience in building secure and scalable web applications.</li>
  <li>Familiarity with cloud platforms and services.</li>
</ul>

<p>If you are passionate about front-end development and enjoy working on innovative SaaS products, we would love to hear from you. Join us in creating exceptional user experiences for our customers.</p>

  `
};

export default jobDescriptions;