// jobData.js
const jobData = [
  {
    id: 1,
    technology: "QA",
    location: "India",
    type: "Full-time",
    button: "Apply now",
    descriptionId: "qa"
  },
  {
    id: 2,
    technology: "Marketing Intern",
    location: "India",
    type: "Contract",
    button: "Apply now",
    descriptionId: "marketingIntern"
  },
  {
    id: 3,
    technology: "Front end developer",
    location: "India",
    type: "Full-time",
    button: "Apply now",
    descriptionId: "frontendDeveloper"
  }
];

export default jobData;