import React, { useEffect } from 'react';
import {Route, Switch} from "react-router-dom";
import Home from './home';
import Contact from './contact';
import About from './about';
import Career from './career';
import './App.css';
import Privacy from './privacy';
import Terms from './terms';
import Services from './services';
import Description from './jobDescription';
import Training from './training';


import TagManager from 'react-gtm-module'
import FAQ from "./faq";

const tagManagerArgs = {
  gtmId: 'GTM-PWWS9GJ7'
}


const App = () => {

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route path='/contact/' component={Contact}/>
      <Route path='/about/' component={About}/>
      <Route path='/career/' component={Career}/>
      <Route path='/privacy/' component={Privacy}/>
      <Route path='/terms/' component={Terms}/>
      <Route path='/services/' component={Services}/>
      <Route path='/job-description/:id' component={Description}/>
      <Route path='/internship-program/' component={Training}/>
      <Route path='/faq/' component={FAQ}/>
      {/*<Description/>*/}
    </Switch>
    </>
  );
};

export default App;
